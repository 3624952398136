import React from "react";

export default function INSTA(props) {
  return (
    <a href="https://www.instagram.com/paketmanapp/" target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        viewBox="0 0 19 19"
        {...props}
      >
        <g>
          <g>
            <path d="M13.15 1.184a4.451 4.451 0 0 1 4.435 4.435v7.58a4.451 4.451 0 0 1-4.436 4.436H5.57a4.451 4.451 0 0 1-4.436-4.436v-7.58A4.451 4.451 0 0 1 5.57 1.184zm0 17.038c2.76 0 5.022-2.262 5.022-5.023v-7.58c0-2.76-2.262-5.022-5.023-5.022H5.57C2.81.597.547 2.859.547 5.619v7.58c0 2.76 2.262 5.023 5.023 5.023z" />
          </g>
          <g>
            <path d="M13.15 2.946a2.682 2.682 0 0 1 2.672 2.673v7.579a2.683 2.683 0 0 1-2.673 2.674H5.57a2.683 2.683 0 0 1-2.673-2.674V5.619A2.682 2.682 0 0 1 5.57 2.946zm0 13.513a3.273 3.273 0 0 0 3.26-3.261V5.619a3.272 3.272 0 0 0-3.26-3.26H5.57a3.272 3.272 0 0 0-3.26 3.26v7.579a3.272 3.272 0 0 0 3.26 3.261z" />
          </g>
          <g>
            <path d="M9.36 5.296a4.125 4.125 0 0 1 4.113 4.113 4.124 4.124 0 0 1-4.113 4.112A4.124 4.124 0 0 1 5.248 9.41 4.124 4.124 0 0 1 9.36 5.296zm0 8.813c2.585 0 4.7-2.115 4.7-4.7s-2.115-4.7-4.7-4.7a4.714 4.714 0 0 0-4.7 4.7c0 2.585 2.115 4.7 4.7 4.7z" />
          </g>
          <g>
            <path d="M9.36 6.766a2.633 2.633 0 0 1 2.644 2.644 2.633 2.633 0 0 1-2.644 2.643A2.633 2.633 0 0 1 6.716 9.41 2.633 2.633 0 0 1 9.36 6.766zm0 5.875a3.221 3.221 0 0 0 3.23-3.231 3.222 3.222 0 0 0-3.23-3.232A3.222 3.222 0 0 0 6.129 9.41a3.221 3.221 0 0 0 3.23 3.23z" />
          </g>
          <g>
            <path d="M14.941 4.709a.866.866 0 0 0-.88-.881.866.866 0 0 0-.883.881c0 .499.383.881.882.881a.866.866 0 0 0 .881-.881z" />
          </g>
        </g>
      </svg>
    </a>
  );
}
