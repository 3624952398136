import React from "react";

export default function TIKTOK(props) {
  return (
    <a href="https://www.tiktok.com/@paketmanapp" target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="20"
        viewBox="0 0 17 20"
        {...props}
      >
        <g>
          <g>
            <path d="M3.191 8.484c-.003-.007-.007-.01-.01-.018a.353.353 0 0 0-.505-.117c-.004 0-.004.003-.007.003a.353.353 0 0 0-.103.484c.004.002.007.01.011.017.106.168.33.22.497.11.004 0 .004-.003.008-.003a.359.359 0 0 0 .11-.476z" />
          </g>
          <g>
            <path d="M9.363 17.576a.36.36 0 0 0-.464-.04 5.137 5.137 0 0 1-2.168.855 5.43 5.43 0 0 1-.783.06 5.191 5.191 0 0 1-3.681-1.515 5.14 5.14 0 0 1-1.525-3.652c0-1.235.457-2.449 1.266-3.38a.36.36 0 0 0-.004-.48L2 9.421a.366.366 0 0 0-.545.003A5.916 5.916 0 0 0 .01 13.288a5.84 5.84 0 0 0 1.74 4.172 5.91 5.91 0 0 0 4.197 1.728 5.887 5.887 0 0 0 3.375-1.045.36.36 0 0 0 .047-.55c-.004-.013-.004-.017-.007-.017z" />
          </g>
          <g>
            <path d="M9.999 16.523a.28.28 0 0 0-.029.037.357.357 0 0 0 .066.516.359.359 0 0 0 .5-.055.28.28 0 0 0 .029-.037.36.36 0 0 0-.066-.516s-.003-.004-.006-.004a.359.359 0 0 0-.494.06z" />
          </g>
          <g>
            <path d="M16.396 6.836l-.003-1.422a.371.371 0 0 0-.366-.395 4.164 4.164 0 0 1-4.15-4.117.365.365 0 0 0-.365-.362H8.616c-.2 0-.365.165-.365.366v7.992l-.004 4.379c0 .984-.632 1.854-1.572 2.164a2.292 2.292 0 0 1-3.016-2.13 2.289 2.289 0 0 1 3.02-2.202.374.374 0 0 0 .391-.106.39.39 0 0 0 .088-.248v-2.95a.367.367 0 0 0-.31-.363 6.084 6.084 0 0 0-2.768.235c-.194.065-.286.28-.194.464v.004c.011.018.022.04.03.058a.328.328 0 0 0 .397.164 5.21 5.21 0 0 1 2.11-.237v2.172a3.016 3.016 0 0 0-2.632.866 2.98 2.98 0 0 0-.867 2.153 3.02 3.02 0 0 0 2.874 2.96c.376.02.745-.032 1.104-.148a3.008 3.008 0 0 0 2.077-2.86l.003-4.38V1.273h.954c.44-.004.921-.007 1.23-.004a4.855 4.855 0 0 0 1.428 3.07 4.875 4.875 0 0 0 3.074 1.405c0 .548.004 1.612.008 2.179a6.987 6.987 0 0 1-2.384-.545 6.965 6.965 0 0 1-1.56-.91.373.373 0 0 0-.384-.041.364.364 0 0 0-.205.329l.014 6.548a5.134 5.134 0 0 1-.446 2.077.357.357 0 0 0 .132.445c.004 0 .004.004.007.004a.365.365 0 0 0 .53-.153 5.874 5.874 0 0 0 .51-2.373l-.023-5.853a7.749 7.749 0 0 0 4.164 1.206.361.361 0 0 0 .369-.365z" />
          </g>
        </g>
      </svg>
    </a>
  );
}
